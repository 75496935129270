import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const DefaultMDXRenderer = (props) => {
  const components = {
    wrapper: (props) => (
      <main
        className={
          props.type === 'timeline'
            ? 'markdown-content timeline'
            : props.type === 'wiki'
            ? 'markdown-wiki-content'
            : 'markdown-content'
        }
        {...props}
      />
    ),
  }

  return <MDXRenderer components={components} {...props} />
}

export default DefaultMDXRenderer
