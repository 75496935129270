import DefaultMDXRenderer from 'components/shared/DefaultMDXRenderer'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-feather'

const ShortDiaryListItem = ({ diary, siteUrl }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleClickTitle = (path) => {
    var tempTextarea = document.createElement('textarea')
    tempTextarea.value = siteUrl + path
    document.body.appendChild(tempTextarea)

    tempTextarea.select()
    document.execCommand('copy')
    document.body.removeChild(tempTextarea)

    setIsCopied(true)
    // TODO: N초 뒤에 사라지도록 하는 효과 추가하기
  }

  return (
    <li>
      <div className='py-8'>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => handleClickTitle(diary.frontmatter.path)}
        >
          <h1 className='text-2xl font-medium'>{diary.frontmatter.title}</h1>{' '}
          <Link
            className='text-gray-400 hover:text-primary-darken transition duration-500 ml-2'
            size='16px'
          />
          <div
            className={
              'bg-black bg-opacity-0 ml-2 text-sm rounded-lg text-white px-2 py-0.5 transition duration-150' +
              (isCopied && ' bg-opacity-50')
            }
          >
            Copied!
          </div>
        </div>

        <div className='text-sm mt-1 text-gray-500'>
          {diary.frontmatter.createdAt}
        </div>

        <div className='my-4'>
          <DefaultMDXRenderer type='timeline'>{diary.body}</DefaultMDXRenderer>
        </div>

        <div className='text-sm text-gray-500'>
          {diary.frontmatter.tags &&
            diary.frontmatter.tags.map((tag) => (
              <span key={tag} className='mr-1'>
                #{tag}
              </span>
            ))}
        </div>
      </div>
    </li>
  )
}

export default ShortDiaryListItem
