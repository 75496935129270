import React from 'react'
import Layout from 'components/shared/Layout'
import SEO from 'components/shared/SEO'
import { graphql } from 'gatsby'
import ShortDiaryListItem from 'components/log/ShortDiaryListItem'

const ShortDiary = ({ location, data }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
    allMdx: { nodes: shortDiaris },
  } = data

  const title = 'Short Diary'
  const description =
    'Records of Thoughts, Feelings and Lessons that are Too Short to be an Article.'

  return (
    <>
      <SEO title={title} description={description} />

      <Layout location={location}>
        <div className='wrapper'>
          <header className='py-14'>
            <h1 className='text-3xl md:text-4xl font-bold mb-4'>{title}</h1>
            <p className='text-gray-500 text-sm md:text-base'>{description}</p>
          </header>

          <ul className='divide-y divide-gray-200'>
            {shortDiaris &&
              shortDiaris.map((diary) => (
                <ShortDiaryListItem
                  key={diary.id}
                  diary={diary}
                  siteUrl={siteUrl}
                />
              ))}
          </ul>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query AllShortDiary {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allMdx(
      filter: { frontmatter: { path: { regex: "^/short-diary/" } } }
      sort: { order: DESC, fields: frontmatter___createdAt }
    ) {
      nodes {
        frontmatter {
          createdAt(formatString: "YYYY년 MM월 DD일")
          differenceDays: createdAt(difference: "days")
          title
          tags
          path
        }
        id
        excerpt(pruneLength: 200, truncate: true)
        rawBody
        body
      }
    }
  }
`

export default ShortDiary
